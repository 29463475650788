import {
  Autocomplete,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import moment from 'moment-timezone';
import * as React from 'react';
import * as Redux from 'react-redux';
import { ButtonFile, Modal } from '../../../../components';
import { acceptOnlyImages } from '../../../../components/ButtonFile';
import LoadingComponent from '../../../../components/Loading';
import { MORADO_COLOR } from '../../../../constants/ColorsConst';
import * as CONST from '../../../../constants/FieldsConst';
import { dateformat, getDateTimezone } from '../../../../helpers';
import { validFielddHelper } from '../../../../helpers/ValidFieldsHelper';
import { useNotification } from '../../../../helpers/notification';
import {
  bulkExcelEventosAction,
  deleteEventosAction,
  getAllAreasAction,
  getAllEventosAction,
  getAllSubAreasAction,
  saveEventosAction,
  setActivoSubAreasAction,
} from '../../../../store/Actions';
import BotonNuevoComponent from '../../../entidades/components/BotonNuevo';
import { FechaField } from '../../../usuarios/components/FieldsUsuarios';
import SelectFieldTiposEventosComponent from '../../tipos/components/SelectFieldTiposEventos';
import { getAllAreasService } from '../../../areas/AreasService';
import { getAllSubAreasService } from '../../../subareas/SubAreasService';

const getRandColor = () => {
  return '#' + Math.floor(Math.random() * 16777215).toString(16);
};

const FormEventos = ({ setOpenModalForm }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const eventosStore = Redux.useSelector((state) => state.eventos);
  const areasStore = Redux.useSelector((state) => state.areas);
  const subareasStore = Redux.useSelector((state) => state.subareas);
  const tiposEventosStore = Redux.useSelector((state) => state.tiposEventos);

  const activo = eventosStore.activo;

  const timezone = entidadesStore.activo.country.timezone;

  const date = dateformat(null, {
    full: true,
  });

  const defaultStartTime = '07:00:00';
  const defaultEndTime = '23:00:00';

  const dataDefault = {
    [CONST.NAME]: '',
    [CONST.DESCRIPTION]: '',
    [CONST.PRICE]: 0,
    [CONST.DATE]: date,
    [CONST.END_DATE]: date,
    [CONST.START_TIME]: defaultStartTime,
    [CONST.END_TIME]: defaultEndTime,
    [CONST.PLACE]: '',
    [CONST.COLOR]: getRandColor(),
    [CONST.ESTATUS]: 'ACTIVE',
    areAllTimesEqual: true,
    subareaIds: [],
    isCustomPlace: false,
    confirmationUrl: '',
    dates: [
      {
        date,
        startTime: defaultStartTime,
        endTime: defaultEndTime,
      },
    ],
  };
  const [evento, setEvento] = React.useState(dataDefault);

  const [area, setArea] = React.useState(null);
  const [subareaId, setSubAreaId] = React.useState(null);
  const [subareasSelect, setSubareasSelect] = React.useState([]);
  const [isLugarPerso, setIsLugarPerso] = React.useState(false);

  const [modalConfirm, setModalConfirm] = React.useState(false);

  const [dataError, setDataError] = React.useState({
    [CONST.NAME]: false,
    [CONST.DATE]: false,
    // [CONST.PLACE]: false,
    [CONST.START_TIME]: false,
  });
  
  const [loadingSave, setLoadingSave] = React.useState(false);

  const [fileExcel, setFileExcel] = React.useState();
  const [loadingExcel, setLoadingExcel] = React.useState(false);
  const [modalExcel, setModalExcel] = React.useState(false);

  const [flyer, setFlyer] = React.useState();
  const [image, setImage] = React.useState();
  const [areas, setAreas] = React.useState([]);
  const [subareas, setSubareas] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      if (areasStore.all && areasStore.all.length === 0) {
        await getAllAreasAction(dispatch, {
          entityId: entidadesStore.activo.id,
        });
      }
      setActivoSubAreasAction(dispatch, null);
    };
    fetchData();
    
    getAreas().then(() => {
      getSubareas();
    });
  }, []);

  

  

  const getAreas = async (params = {}) => {
    const res = await getAllAreasService({
      entityId: entidadesStore.activo.id,
    });
    const data = res.data.data;
    const areas = data.map((el) => ({
      id: el.id,
      name: el.name,
      imageUrl: el.imageUrl,
      label: el.name,
      openingTime: el.openingTime,
      closingTime: el.closingTime,
      blockReservation: el.blockReservation,
    }));
    if (res) setAreas(areas);
  };

  const getSubareas = async (params = {}) => {
    const res = await getAllSubAreasService({
      entityId: entidadesStore.activo.id,
    });
    const data = res.data.data;
    const subareas = data.map((el) => ({
      id: el.id,
      name: el.name,
      imageUrl: el.imageUrl,
      label: `${el.area.name} | ${el.name}${el.price ? ' ($)' : ''}`,
      dates: el.dates,
      openingTime: el.openingTime,
      closingTime: el.closingTime,
      interval: el.interval,
      areaName: el.area.name,
      price: el.price,
    }));
    if (res) setSubareas(subareas);
  };

  const isSameDay = dayjs(evento.date).isSame(dayjs(evento.endDate), 'day');

  React.useEffect(() => {
    if (tiposEventosStore.all.length > 0) {
      _handleSetDataField(CONST.TYPE_ID, tiposEventosStore.all[0].id);
    }
  }, [tiposEventosStore.all]);

  React.useEffect(() => {
    if (activo === null) {
      setEvento(dataDefault);
      setArea(null);
      setSubAreaId(null);
      setSubareasSelect([]);
      setFlyer(null);
      setImage(null);
      return;
    }
    let data = {};
    Object.keys(evento).map((key) => (data[key] = eventosStore.activo[key]));
    setTimeout(() => {
      setEvento({
        ...data,
        [CONST.TYPE_ID]: activo.type?.id ?? '',
      });
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activo]);

  const _handleSetDataField = (key, value) =>
    setEvento({ ...evento, [key]: value });

  const _validDataForm = () => {
    let r = {};
    let va = [];
    Object.keys(dataError).forEach((key) => {
      const val = evento[key];
      const valid = val === null || val === '' || val === 0;
      r[key] = valid;
      va.push(valid);
    });
    setDataError(r);
    return !va.includes(true);
  };

  const _handleSubmit = async () => {
    const valid = _validDataForm();

    if (valid) {
      const data = {
        ...evento,
        image,
        flyer,
        color: evento.color.toLocaleUpperCase(),
        date: getDateTimezone(moment(evento.date).startOf('day'), timezone),
        endDate: getDateTimezone(
          moment(evento.endDate).startOf('day'),
          timezone
        ),
        startTime: dateformat(evento.startTime, {
          complete: true,
          isHHmmss: true,
        }),
        endTime: dateformat(evento.endTime, {
          complete: true,
          isHHmmss: true,
        }),
        userCodeId: null,
        entityId: entidadesStore.activo.id,
      };

      setLoadingSave(true);
      const res = await saveEventosAction(
        { dispatch, addNotification },
        activo ? activo.id : null,
        data,
        eventosStore.params
      );
      if (res) {
        setEvento(dataDefault);
        setArea(null);
        setSubAreaId(null);
        setSubareasSelect([]);
        setFlyer(null);
        setImage(null);
        setOpenModalForm(false);
      }
      setLoadingSave(false);
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      const xDate = dateformat(eventosStore.slotInfo?.start, {});
      const xEndDate = dateformat(eventosStore.slotInfo?.end, {});
      evento.date = xDate;
      evento.endDate = xEndDate;
      evento.startTime = evento.startTime;
      evento.endTime = evento.endTime;
      evento.dates = eventosStore.slotInfo?.slots.map((date) => ({
        date: new Date(date),
        startTime: evento.startTime,
        endTime: evento.endTime,
      }));
      if (tiposEventosStore.all.length > 0) {
        evento.typeId = tiposEventosStore.all[0].id;
      }

      if (new Date(xDate) < dayjs()) {
        evento.date = dateformat(dayjs(), {});
        evento.endDate = dateformat(dayjs(), {});
      }
      setEvento(evento);
    }, 500);
  }, [eventosStore.slotInfo]);


  const comparaHoras = (inicio, a, b) => {
    const vInicio = a;
    const vFinal = b;

    const tIni = new Date();
    const pInicio = vInicio.split(':');
    tIni.setHours(pInicio[0], pInicio[1]);

    const tFin = new Date();
    const pFin = vFinal.split(':');
    tFin.setHours(pFin[0], pFin[1]);

    if (inicio) {
      if (tIni.getTime() < tFin.getTime()) {
        return false;
      } else if (tIni.getTime() >= tFin.getTime()) {
        return true;
      }
    }

    if (tFin.getTime() > tIni.getTime()) {
      return false;
    } else if (tFin.getTime() <= tIni.getTime()) {
      return true;
    }
  };

  function tableFecha() {
    return (
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '43%' }} align="left">
                Fecha
              </TableCell>
              <TableCell align="center">Hora inicio</TableCell>
              <TableCell align="center">Hora final</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {evento.dates.map((item, index) => {
              return (
                <TableRow
                  key={`row_${index}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left">
                    {dateformat(item.date, { format: 'DD/MM/YYYY' })}
                  </TableCell>
                  <TableCell align="left">
                    <FormControl sx={{ width: '100%' }}>
                      <TextField
                        size="small"
                        type="time"
                        value={item.startTime}
                        onChange={(e) => {
                          const res = comparaHoras(
                            true,
                            e.target.value,
                            evento.dates[index].endTime
                          );
                          if (!res) {
                            const dates = [...evento.dates];
                            dates[index].startTime = `${e.target.value}:00`;
                            setEvento({ ...evento, dates });
                          }
                        }}
                        id={CONST.START_TIME}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell align="left">
                    <FormControl sx={{ width: '100%' }}>
                      <TextField
                        size="small"
                        type="time"
                        value={item.endTime}
                        onChange={(e) => {
                          const res = comparaHoras(
                            false,
                            evento.dates[index].startTime,
                            e.target.value
                          );
                          if (!res) {
                            const dates = [...evento.dates];
                            dates[index].endTime = `${e.target.value}:00`;
                            setEvento({ ...evento, dates });
                          }
                        }}
                        id={CONST.START_TIME}
                      />
                    </FormControl>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <Box component="form" sx={{ m: 2 }}>
      {/* <pre>{JSON.stringify(evento, null, 2)}</pre> */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <LoadingComponent
            isLoading={loadingSave}
            text={activo ? 'Actualizando...' : 'Guardando...'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ButtonFile
            id="Imagen"
            onFile={(file) => setImage(file)}
            file={image}
            text="Subir Image"
            onDelete={() => setImage(null)}
            st={{ backgroundColor: 'gray' }}
            src={image ? null : activo?.imageUrl}
            accept={acceptOnlyImages}
            sxc={{ width: '50%' }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ButtonFile
            id="flyer"
            onFile={(file) => setFlyer(file)}
            file={flyer}
            text="Subir Flyer"
            onDelete={() => setFlyer(null)}
            st={{ backgroundColor: 'gray' }}
            src={flyer ? null : activo?.flyerUrl}
            accept={acceptOnlyImages}
            sxc={{ width: '50%' }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                size="small"
                id={CONST.NAME}
                required
                label="Nombre"
                value={evento.name}
                onChange={(e) =>
                  _handleSetDataField(CONST.NAME, e.target.value)
                }
                helperText={dataError.name ? validFielddHelper(1) : null}
                error={dataError.name}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                size="small"
                id={CONST.PRICE}
                required
                label="Costo"
                value={evento.price}
                type="number"
                inputProps={{ min: 0 }}
                min={0}
                onChange={(e) =>
                  _handleSetDataField(CONST.PRICE, parseFloat(e.target.value))
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                size="small"
                id={CONST.COLOR}
                required
                label="Color"
                value={evento.color}
                type="color"
                min={1}
                onChange={(e) => {
                  _handleSetDataField(CONST.COLOR, e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectFieldTiposEventosComponent
                value={evento.typeId}
                isAll={false}
                onChange={(id) => _handleSetDataField(CONST.TYPE_ID, id)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FechaField
                l="Fecha inicio"
                v={evento.date}
                on={(e) => {
                  let value = dateformat(e, { full: true });

                  const data = {
                    [CONST.DATE]: value,
                  };

                  let end = new Date(
                    dateformat(evento.endDate, { full: true })
                  );
                  if (new Date(value) > new Date(evento.endDate)) {
                    data[CONST.END_DATE] = value;
                    end = new Date(value);
                  }

                  const dates = [];
                  let startDate = new Date(value);
                  while (startDate <= end) {
                    dates.push({
                      date: new Date(startDate),
                      startTime: evento.startTime,
                      endTime: evento.endTime,
                    });
                    startDate.setDate(startDate.getDate() + 1);
                  }

                  setEvento({
                    ...evento,
                    ...data,
                    dates,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FechaField
                l="Fecha final"
                v={evento.endDate}
                on={(e) => {
                  let value = dateformat(e, { full: true });
                  const data = {
                    [CONST.END_DATE]: value,
                  };
                  let startDate = new Date(
                    dateformat(evento.date, { full: true })
                  );
                  if (new Date(value) < new Date(evento.date)) {
                    data[CONST.DATE] = value;
                    startDate = new Date(value);
                  }

                  const dates = [];
                  let end = new Date(value);
                  while (startDate <= end) {
                    dates.push({
                      date: new Date(startDate),
                      startTime: evento.startTime,
                      endTime: evento.endTime,
                    });
                    startDate.setDate(startDate.getDate() + 1);
                  }

                  setEvento({
                    ...evento,
                    ...data,
                    dates,
                  });
                }}
              />
            </Grid>
            {!isSameDay && (
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={evento.areAllTimesEqual}
                        checked={evento.areAllTimesEqual}
                        onChange={(e) => {
                          let dates = evento.dates;
                          if (e.target.checked) {
                            dates = evento.dates.map((date) => ({
                              ...date,
                              startTime: evento.startTime,
                              endTime: evento.endTime,
                            }));
                          }
                          setEvento({
                            ...evento,
                            dates,
                            areAllTimesEqual: e.target.checked,
                          });
                        }}
                      />
                    }
                    label="Aplicar la misma horas a todas las fechas"
                  />
                </FormControl>
              </Grid>
            )}
            {(evento.areAllTimesEqual || isSameDay) && (
              <Grid item xs={12} md={6}>
                <FormControl sx={{ width: '100%' }}>
                  <TextField
                    size="small"
                    type="time"
                    label="Hora de inicio"
                    value={evento.startTime}
                    onChange={(e) => {
                      const res = comparaHoras(
                        true,
                        e.target.value,
                        evento.endTime
                      );
                      if (!res) {
                        const dates = evento.dates.map((date) => ({
                          ...date,
                          startTime: `${e.target.value}:00`,
                        }));
                        setEvento({
                          ...evento,
                          dates,
                          startTime: `${e.target.value}:00`,
                        });
                      }
                    }}
                    id={CONST.START_TIME}
                  />
                </FormControl>
              </Grid>
            )}
            {(evento.areAllTimesEqual || isSameDay) && (
              <Grid item xs={12} md={6}>
                <FormControl sx={{ width: '100%' }}>
                  <TextField
                    size="small"
                    type="time"
                    label="Hora final"
                    value={evento.endTime}
                    onChange={(e) => {
                      const res = comparaHoras(
                        true,
                        evento.startTime,
                        e.target.value
                      );
                      if (!res) {
                        const dates = evento.dates.map((date) => ({
                          ...date,
                          endTime: `${e.target.value}:00`,
                        }));
                        setEvento({
                          ...evento,
                          dates,
                          endTime: `${e.target.value}:00`,
                        });
                      }
                    }}
                    id={CONST.END_TIME}
                  />
                </FormControl>
              </Grid>
            )}
            {!evento.areAllTimesEqual && !isSameDay && (
              <Grid item xs={12} md={12}>
                {tableFecha()}
              </Grid>
            )}
            {/* 
          {isLugarPerso && (
            <TextField
              fullWidth
              size="small"
              id={CONST.PLACE}
              multiline
              rows={2}
              label="Descripción del lugar"
              value={evento.place}
              onChange={(e) => _handleSetDataField(CONST.PLACE, e.target.value)}
            />
          )}
        </Grid> */}
            {/* {!isLugarPerso && (
          <Grid item xs={12} md={12}>
            {selectAreas}
          </Grid>
        )}
        {!isLugarPerso && (
          <Grid item xs={12} md={12}>
            <LoadingComponent isLoading={subareasStore.loadingGetAll} />
            {selectSubAreas}
            <div style={{ marginTop: '10px' }}>
              {subareasSelect.map((e) => {
                return (
                  <Chip
                    label={e.name}
                    onClick={() => {}}
                    sx={{ mr: 1, mb: 1 }}
                    color="default"
                    onDelete={() => {
                      setSubareasSelect(
                        subareasSelect.filter((o) => o.id !== e.id)
                      );
                    }}
                  />
                );
              })}
            </div>
          </Grid>
        )} */}

            {/* <Grid item xs={12} md={12}>
          <FormControl size="small" sx={{ width: '100%' }}>
            <InputLabel id="selectTipoPago">Estatus</InputLabel>
            <Select
              labelId="selectTipoPago"
              size="small"
              label="Estatus"
              value={evento.status}
              onChange={(e) =>
                _handleSetDataField(CONST.STATUS, e.target.value)
              }
              id={CONST.STATUS}
            >
              <MenuItem value="debt">Deuda</MenuItem>
              <MenuItem value="pay">Pagado</MenuItem>
              <MenuItem value="pending">Pendiente</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                
                <InputLabel id="level">Evento en subáreas</InputLabel>
                <Select
                  id="isCustomPlace"
                  value={evento.isCustomPlace}
                  labelId="isCustomPlace"
                  label="Evento en subáreas"
                  onChange={(e) => {
                    setEvento({ ...evento, isCustomPlace: e.target.value });
                  }}
                >
                  <MenuItem value={true}>Si</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {
              !evento.isCustomPlace ? (
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    size="small"
                    id={CONST.PLACE}
                    multiline
                    required
                    rows={2}
                    label="Lugar"
                    value={evento.place}
                    onChange={(e) =>
                      _handleSetDataField(CONST.PLACE, e.target.value)
                    }
                    helperText={dataError.place ? validFielddHelper(1) : null}
                    error={dataError.place}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} md={12}>
                  <FormControl fullWidth>
                    <Autocomplete
                      fullWidth
                      disableClearable
                      disableCloseOnSelect={true}
                      id="combo-box-demo"
                      options={subareas.map((el) => el.id)}
                      multiple
                      value={evento.subareaIds}
                      size="small"
                      getOptionLabel={(option) => subareas.find((el) => el.id === option).label}
                      renderInput={(params) => {
                        return (
                          <Grid>
                            <TextField
                              {...params}
                              label="Subáreas"
                              placeholder="Buscar subáreas"
                            />
                          </Grid>
                        );
                      }}
                      renderOption={(props, id, { selected }) => {
                        const { key, ...optionProps } = props;
                        const option = subareas.find((el) => el.id === id);
                        return (
                          <li key={key} {...optionProps}>
                            <img
                              style={{
                                width: 50,
                                height: 50,
                                marginRight: 10,
                                borderRadius: '50%',
                                objectFit: 'cover',
                                display: 'block',
                              }}
                              src={option.imageUrl}
                              alt={option.label}
                            />
                            {option.label}
                          </li>
                        );
                      }}
                      key={(option) => option.id}
                      onChange={(e, value) => {
                        setEvento({ ...evento, subareaIds: value });
                      }}
                    />
                  </FormControl>
                  {/* <Typography variant="caption" color="textSecondary">
                    Si existen reservaciones o bloqueos en las subáreas seleccionadas, estas se eliminarán y se bloquearán las horas para el evento.
                  </Typography> */}
                </Grid>
              )
            }
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                size="small"
                id={CONST.DESCRIPTION}
                multiline
                rows={2}
                label="Descripción"
                value={evento.description}
                onChange={(e) =>
                  _handleSetDataField(CONST.DESCRIPTION, e.target.value)
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} sx={{ m: 2 }}>
          <Stack direction="row" justifyContent="center" spacing={1}>
            {
              eventosStore.activo && eventosStore.activo.confirmationUrl && (
                <Button
                  sx={{ color: MORADO_COLOR }}
                  onClick={() => {
                    console.log(evento);
                    window.navigator.clipboard.writeText(evento.confirmationUrl).then(() => {
                      addNotification({ message: 'URL copiada al portapapeles', type: 'success' });
                    }).catch((err) => {
                      addNotification({ message: 'Error al copiar la URL', type: 'error' });
                    });
                  }}
                >
                  Copiar url del evento
                </Button>
              )
            }
            <BotonNuevoComponent
              click={
                async () => {
                  if (evento.isCustomPlace) {
                    setModalConfirm(true);
                  } else {
                    await _handleSubmit();
                  }
                }
              }
              text="GUARDAR"
              disabled={loadingSave}
              morado
            />
            {activo && (
              <BotonNuevoComponent
                click={async () => {
                  await deleteEventosAction(
                    { dispatch, addNotification },
                    eventosStore.activo.id,
                    eventosStore.params
                  );
                }}
                text="ELIMINAR"
                disabled={loadingSave}
                red
              />
            )}
          </Stack>
          {/* <center>
            <BotonNuevoComponent
              click={() => {
                window.location.href =
                  process.env.REACT_APP_API_URL +
                  '/bulk-schemas/payment-schema.xlsx';
              }}
              text="BAJAR FORMATO DE EXCEL"
              disabled={loadingSave}
              cyan
              mt={1}
            />
          </center> */}
        </Grid>
      </Grid>
      <Modal
        id="modalFileExcel"
        title={`Eventos masivos`}
        open={modalExcel}
        maxWidth="sm"
        onClose={() => setModalExcel(!modalExcel)}
      >
        <DialogContent>
          <LoadingComponent
            isLoading={loadingExcel}
            text="Procensando archivo excel..."
          />
          <ButtonFile
            id={'archivoexcel'}
            onFile={(file) => setFileExcel(file)}
            file={fileExcel}
            text="Buscar archivo"
            sxc={{ marginTop: '5px' }}
            size="40%"
            name={'excel'}
            onDelete={() => setFileExcel(null)}
            accept=".xlsx"
          />
        </DialogContent>
        <DialogActions>
          <BotonNuevoComponent
            click={async () => {
              if (fileExcel === null || fileExcel === undefined) {
                return;
              }
              setLoadingExcel(true);
              await bulkExcelEventosAction(
                { dispatch, addNotification },
                {
                  entityId: entidadesStore.activo.id,
                  file: fileExcel,
                }
              );
              setLoadingExcel(false);
              setModalExcel(false);
              setFileExcel(null);
              getAllEventosAction(dispatch, {
                entityId: entidadesStore.activo.id,
                ...eventosStore.params,
              });
            }}
            text="SUBIR ARCHIVO"
            morado
          />
          <Button
            sx={{ color: MORADO_COLOR }}
            onClick={() => {
              setLoadingExcel(false);
              setModalExcel(false);
              setFileExcel(null);
            }}
          >
            CANCELAR
          </Button>
        </DialogActions>
      </Modal>
      
      
      <Modal
          id="modalToggleActivo"
          title="Confirmar"
          open={modalConfirm}
          maxWidth="sm"
          onClose={() => setModalConfirm(false)}
        >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Si existen reservaciones o bloqueos en las subáreas seleccionadas, estas se eliminarán y se bloquearán las horas para el evento.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="outlined"
            onClick={async () => {
              setModalConfirm(false);
              await _handleSubmit();
            }}
          >
            ACEPTAR
          </Button>
          <Button
            sx={{ color: MORADO_COLOR }}
            onClick={() => setModalConfirm(false)}
          >
            CANCELAR
          </Button>
        </DialogActions>
      </Modal>
    </Box>
  );
};

export default FormEventos;
